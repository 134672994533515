export const GET_USER_ROLES = 'app/Action/GET_USER_ROLES';
export const STORE_FETCHED_PROVIDER = 'app/Action/STORE_FETCHED_PROVIDER';
export const STORE_FETCHED_TIMEZONES = 'app/Action/STORE_FETCHED_TIMEZONES';
export const INITIALIZE = 'app/Action/INITIALIZE';

export const FETCH_AUTH_ROLES = 'app/Action/FETCH_AUTH_ROLES';
export const STORE_AUTH_ROLES = 'app/Action/STORE_AUTH_ROLES';
export const CREATE_AUTH_USER = 'app/Action/CREATE_AUTH_USER';
export const FEATURE_REQUEST = 'app/Action/FEATURE_REQUEST';
export const REPORT_ISSUE = 'app/Action/REPORT_ISSUE';
export const DUPLICATE_FORM = 'app/Action/DUPLICATE_FORM';
