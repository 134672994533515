import {
  isArray,
  map,
  toNumber,
  isEmpty,
  trim,
  split,
  compact,
  get,
  find,
  reduce,
  has,
  isObject,
  isNil,
  range,
  includes,
} from 'lodash';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';
import { triggerToast } from 'components/base/Notification';
import localforage from 'localforage';
import {
  ROLE_ADMIN,
  ROLE_EVALUATOR,
  ROLE_RECRUITER,
  ROLE_MASTER_RECRUITER,
  ROLE_HIRING_MANAGER,
  ROLE_TENANT_ADMIN,
  ROLE_CONTENT_MANAGER,
  ROLE_MASTER_CONTENT_MANAGER,
  ROLE_COLLABORATOR,
  getCurrentRole,
} from './roles';
import getDisplayName from './getDisplayName';

export function isNumeric(str) {
  if (typeof str !== 'string') return false; // we only process strings!
  // eslint-disable-next-line no-restricted-globals
  return !Number.isNaN(str) && !isNaN(str);
}

export function formatBytes(bytes, decimals = 0) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function formattedTime(value) {
  try {
    return value ? DateTime.fromSeconds(value).toISO().substr(11, 8) : null;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
}

export async function getDataByIds(value, getByIds) {
  const v = !isArray(value) ? [trim(value)] : value;
  const ids = map(v, (i) => toNumber(i));

  if (ids.length === 0 || isEmpty(value)) {
    return Promise.resolve([]);
  }

  return getByIds({ ids });
}

export const getParams = (filters, f) =>
  reduce(
    f,
    (o, value, key) =>
      find(filters, { key })
        ? { ...o, ...{ [key]: get(find(filters, { key }), 'isDate', false) ? split(value, ',') : value } }
        : o,
    {},
  );

export const parseJwt = (token) => {
  if (!token) {
    throw new Error('JWT token is missing');
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

export function pageNumbers({ pages, current }) {
  let max = pages;
  if (pages > current + 2 && current <= 3) {
    max = 5;
  } else if (current + 2 < pages) {
    max = current + 2;
  }
  let min = 1;
  if (pages < current + 3 && current > 3) {
    min = pages - 4;
  } else if (current > 3) {
    min = current - 2;
  }
  return range(min, max + 1);
}

export const getQueryString = (filteredParams) =>
  reduce(
    Object.keys(filteredParams),
    (a, i) =>
      has(filteredParams, i) && !isNil(filteredParams[i]) && !isObject(filteredParams[i]) && !isEmpty(filteredParams[i])
        ? `${a ? `${a}&` : ''}${i}=${encodeURIComponent(filteredParams[i])}`
        : a,
    '',
  );

export const parseJson = (items) =>
  compact(
    map(items, (item) => {
      try {
        return JSON.parse(item);
      } catch (e) {
        Sentry.captureException(e);
        return null;
      }
    }),
  );

export const filterUsersDropdown = (option, searchText) => {
  const s = (searchText || '').toString().trim().toLowerCase();
  if (s) {
    let username = get(option, 'data.username', '');
    let external_id = get(option, 'data.external_id', '');
    let name = getDisplayName(option?.data);
    username = (username || '').toString().trim().toLowerCase();
    external_id = (external_id || '').toString().trim().toLowerCase();
    name = (name || '').toString().trim().toLowerCase();
    return username.includes(s) || external_id.includes(s) || name.includes(s);
  }
  return true;
};

export const handleEnterKeyPress = (event, callback) => {
  if (event.keyCode === 13) {
    callback(event);
  }
};

export const catchError = (error, skipToast) => {
  if (process.env.NODE_ENV !== 'production' && console) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  if (!skipToast) {
    triggerToast({
      variant: 'danger',
      setting: { position: 'top-right' },
      message: {
        title: 'Oh! Something went wrong',
        summary: error.message,
      },
    });
  }
  Sentry.captureException(error);
};

export const parseDateTime = (date) => (date && DateTime.fromISO(date).isValid ? DateTime.fromISO(date) : null);
export const parseJSDateTime = (date) => (date && DateTime.fromJSDate(date).isValid ? DateTime.fromJSDate(date) : null);

export const getCurrentDate = () =>
  process.env.NODE_ENV === 'test' || process.env.STORYBOOK_APP_ENV === 'storybook'
    ? parseDateTime('2022-12-07T12:00:00+05:30')
    : DateTime.now();

export const dateCompare = (dateRange, value) => {
  const min = +new Date(dateRange[0]);
  const max = +new Date(dateRange[1]);
  return value && new Date(value) ? +new Date(value) >= min && +new Date(value) <= max : false;
};

export const dateTimeCompare = (row, columnId, filterValue) => {
  const rowValue = row.getValue(columnId);
  return !isNil(rowValue) ? dateCompare(filterValue, rowValue) : false;
};

dateTimeCompare.autoRemove = (val) => !val;

export function formatRoles(role) {
  switch (role) {
    case ROLE_ADMIN:
      return 'Admin';
    case ROLE_RECRUITER:
      return 'Recruiter';
    case ROLE_MASTER_RECRUITER:
      return 'Master Recruiter';
    case ROLE_EVALUATOR:
      return 'Evaluator';
    case ROLE_COLLABORATOR:
      return 'Collaborator';
    case ROLE_CONTENT_MANAGER:
      return 'Content Manager';
    case ROLE_TENANT_ADMIN:
      return 'Tenant Admin';
    case ROLE_HIRING_MANAGER:
      return 'Hiring Manager';
    case ROLE_MASTER_CONTENT_MANAGER:
      return 'Master Content Manager';
    default:
      return role;
  }
}

export const roleMapping = [
  { key: 'TENANT_ADMIN', label: 'Admin' },
  { key: 'CANDIDATE', label: 'Candidate' },
  { key: 'EVALUATOR', label: 'Evaluator' },
  { key: 'RECRUITER', label: 'Recruiter' },
  { key: 'MASTER_RECRUITER', label: 'Master Recruiter' },
  { key: 'HIRING_MANAGER', label: 'Hiring Manager' },
  { key: 'CONTENT_MANAGER', label: 'Content Manager' },
  { key: 'MASTER_CONTENT_MANAGER', label: 'Master Content Manager' },
];

export const emailTemplateTypeMapping = [
  { key: 'invite_candidate_public_link', value: 'Registration Confirmation' },
  { key: 'invite_candidate_with_time', value: 'Interview Invitation' },
  { key: 'invite_panel_with_time', value: 'Interview Invitation' },
  { key: 'invite_candidate_with_slots', value: 'Slot Booking Invitation' },
  { key: 'declined_by_candidate_panel_mail', value: 'RSVP Negotiation' },
  { key: 'declined_by_candidate_recruiter_mail', value: 'RSVP Negotiation' },
  { key: 'declined_by_interviewer_recruiter_mail', value: 'RSVP Negotiation' },
  { key: 'canceled_by_recruiter_interviewer_mail', value: 'Interview Cancellation' },
  { key: 'canceled_by_recruiter_candidate_mail', value: 'Interview Cancellation' },
  { key: 'canceled_interview_recruiter_mail', value: 'Interview Cancellation' },
  { key: 'proposal_declined_by_candidate_interviewer_mail', value: 'RSVP Negotiation' },
  { key: 'proposal_declined_by_interviewer_candidate_mail', value: 'RSVP Negotiation' },
  { key: 'certification_slot_confirmation', value: 'Slot Booking Invitation' },
  { key: 'certification_registration_email', value: 'Registration Confirmation' },
  { key: 'email_otp_verification', value: 'Authentication' },
  { key: 'interviewer_add_to_room', value: 'Drive Availability' },
  { key: 'interviewer_removal_from_room', value: 'Drive Availability' },
  { key: 'reschedule_interview_interviewer', value: 'Interview Rescheduled' },
  { key: 'reschedule_interview_candidate', value: 'Interview Rescheduled' },
  { key: 'reschedule_interview_recruiter', value: 'Interview Rescheduled' },
  { key: 'time_proposed_panel_for_candidate', value: 'RSVP Negotiation' },
  { key: 'time_proposed_candidate_for_interviewer', value: 'RSVP Negotiation' },
  { key: 'interview_confirmed_candidate', value: 'Interview Invitation' },
  { key: 'interview_confirmed_recruiter', value: 'Interview Invitation' },
  { key: 'registration_confirmed', value: ' Registration Confirmation' },
];

export const statusMapping = [
  { key: true, value: 'Active' },
  { key: false, value: 'Inactive' },
];

export function getDataForLocalForage(key) {
  return localforage.getItem(key);
}

export function storeDataToLocalForage(key, value) {
  return localforage.setItem(key, value);
}

export const validateFields = ({ email, name }) => {
  const errors = {};
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!email) {
    errors.email = 'Email is required';
  } else if (!emailRegex.test(email)) {
    errors.email = 'This is not a valid email format';
  }
  if (!name) {
    errors.name = 'Full Name is required';
  }
  return errors;
};

export const getHasuraHeaders = (graphToken) => get(parseJwt(graphToken), 'https://hasura.io/jwt/claims');

export const getHasuraHeaderByName = (graphToken, name) => {
  const parsedHeaders = getHasuraHeaders(graphToken);
  return get(parsedHeaders, name) || get(parsedHeaders, (name || '').toLowerCase());
};

export const getRequiredPathRoleAccess = () => {
  const path = window.location.href;
  const isPanelRoute = path.includes('/panel/');
  if (isPanelRoute) {
    return ROLE_EVALUATOR;
  }
  return ROLE_MASTER_RECRUITER;
};

export const validateRoleAccess = (roles, requiredRole = getRequiredPathRoleAccess()) => includes(roles, requiredRole);

export const getDefaultContext = (graphToken) => {
  const roles = getHasuraHeaderByName(graphToken, 'X-Hasura-Allowed-Roles');
  const requiredRole = getRequiredPathRoleAccess();
  const haveAccess = validateRoleAccess(roles, requiredRole);
  return {
    headers: {
      'x-hasura-role': haveAccess ? requiredRole : getCurrentRole(roles),
    },
  };
};

export const tenantOldIdMap = {
  454: 484,
  243: 496,
  309: 566,
  287: 663,
};
