import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';

import classNames from 'classnames';

import { Cell } from './Cell';

export function Row(props) {
  const { getRowProps, values, cells, id, index, onRowClick } = props;
  const handleClick = () => {
    if (onRowClick) onRowClick(values);
  };
  return (
    <tr
      key={id || get(values, 'id', index)}
      className={classNames('even:bg-gray-50 table-row', index % 2 === 0 ? 'bg-white' : 'bg-gray-50')}
      {...getRowProps()}
      onClick={handleClick}
    >
      {map(cells, (cell) => (
        <Cell
          {...cell}
          key={`${id || get(values, 'id', index)}-${get(cell, 'column.key')}`}
          backgroundClassNames={classNames(index % 2 === 0 ? 'bg-white' : 'bg-gray-50')}
        />
      ))}
    </tr>
  );
}

Row.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getRowProps: PropTypes.func,
  index: PropTypes.number,
  original: PropTypes.object,
  cells: PropTypes.array,
  onRowClick: PropTypes.func,
};

export default memo(Row);
