import { fetchUserDataAction } from 'containers/Users/actions';
import * as query from './queries';
import * as types from './constants';

export const getUserRoles = ({ payload, callback }) => ({
  type: types.GET_USER_ROLES,
  payload,
  callback,
  queryString: query.GET_USER_ROLES_QUERY,
});
export const initialize = () => ({
  type: types.INITIALIZE,
});

export function fetchAuthRoles() {
  return {
    type: types.FETCH_AUTH_ROLES,
  };
}

export function createAuthUser({ payload, callback }) {
  return {
    type: types.CREATE_AUTH_USER,
    payload,
    callback,
    queryString: query.CREATE_AUTH_USER,
    spreadPayload: true,
    key: 'auth_create_user',
    refetchActions: [fetchUserDataAction],
  };
}

export const featureRequest = ({ payload, callback }) => ({
  type: types.FEATURE_REQUEST,
  payload,
  callback,
  queryString: query.FEATURE_REQUEST,
  key: 'admin_create_feature_request',
});
export const reportIssue = ({ payload, callback }) => ({
  type: types.REPORT_ISSUE,
  payload,
  callback,
  queryString: query.REPORT_ISSUE,
  key: 'admin_create_support_ticket',
});

export const duplicateForm = ({ payload, callback }) => ({
  type: types.DUPLICATE_FORM,
  payload,
  callback,
  spreadPayload: true,
  queryString: query.DUPLICATE_FORM,
  key: 'frm_create_form',
});
