/**
 *
 * SidePanel
 *
 */

import React, { memo, Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button';

export function SidePanel(props) {
  const {
    backgroundOverlay,
    outsideCloseButton,
    onSuccess,
    children,
    title,
    show,
    onClose,
    showButtons,
    saveButtonType,
    saveButtonTitle,
    disabledSaveButton,
  } = props;
  const [open, setOpen] = useState(show);
  useEffect(() => {
    setOpen(show);
  }, [show]);
  const closeFunc = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10" onClose={closeFunc}>
        <div className="absolute inset-0 ">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={classNames(
                'absolute inset-0',
                backgroundOverlay ? 'bg-gray-500 bg-opacity-75 transition-opacity' : '',
              )}
            />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                {outsideCloseButton && (
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-10 flex pt-4 pr-2 sm:-ml-8 md:-ml-2 sm:pr-4">
                      <button type="button" className="rounded-md text-gray-300" onClick={closeFunc}>
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                )}

                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="flex min-h-0 flex-1 pr-7 pl-5 flex-col">
                    <div
                      className={classNames(
                        'flex  py-4  align-middle justify-between  top-0  sticky',
                        'w-full shadow-xs ',
                      )}
                    >
                      {title && <div className=" text-base font-semibold text-gray-900 ">{title}</div>}
                      {!outsideCloseButton && (
                        <button type="button" className="flex text-gray-400 hover:text-gray-500" onClick={closeFunc}>
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                    <div className="flex-1 py-2 overflow-y-auto">{children}</div>
                  </div>
                  {showButtons && (
                    <div className="px-5 py-4 space-x-3">
                      {/* <Button type={closeButtonType} onClick={closeFunc}> */}
                      {/*  <p className="text-xs font-medium"> {closeButtonTitle}</p> */}
                      {/* </Button> */}
                      <Button
                        disabled={disabledSaveButton}
                        type={saveButtonType}
                        onClick={(e) => {
                          if (isFunction(onSuccess)) onSuccess(e);
                        }}
                      >
                        <p className="text-xs font-medium"> {saveButtonTitle}</p>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SidePanel.propTypes = {
  backgroundOverlay: PropTypes.bool,
  outsideCloseButton: PropTypes.bool,
  onSuccess: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  show: PropTypes.bool,
  showButtons: PropTypes.bool,
  saveButtonType: PropTypes.string,
  saveButtonTitle: PropTypes.string,
  disabledSaveButton: PropTypes.bool,
};

SidePanel.defaultProps = {
  backgroundOverlay: false,
  outsideCloseButton: false,
  show: false,
  onSuccess: false,
  showButtons: false,
  saveButtonType: 'success',
  saveButtonTitle: 'Create',
};

export default memo(SidePanel);
