/**
 *
 * Select
 *
 */

import React, { memo, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import { matchSorter } from 'match-sorter';

import classNames from 'classnames';

import { filter, uniqBy, find } from 'lodash';

export function Select(props) {
  const { value, label, options, onChange, isMulti, disabled, searchFn, nullable, emptyLabel, required, error } = props;
  const [selectedItems, setSelected] = useState(value || (isMulti ? [] : {}));
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  useEffect(() => {
    const items = isMulti ? uniqBy(selectedItems, 'value') : selectedItems;
    if (onChange) onChange(items);
  }, [selectedItems]);

  useEffect(() => {
    if (searchFn) {
      setLoading(true);
      searchFn(query).then((r) => {
        setFilteredOptions(r);
        setLoading(false);
      });
    } else {
      setFilteredOptions(matchSorter(options, query, { keys: ['searchKey', 'label', 'value'] }));
    }
  }, [query]);

  return (
    <Combobox
      as="div"
      value={selectedItems}
      onChange={setSelected}
      multiple={isMulti}
      nullable={nullable}
      disabled={disabled}
    >
      {label && (
        <Combobox.Label className="block text-sm font-medium text-gray-700 flex justify-between" as="label">
          {label}
          {required && <span className="text-red-400 mr-3">*</span>}
        </Combobox.Label>
      )}
      {isMulti && selectedItems && selectedItems.length > 0 && (
        <ul className={classNames('mb-1 space-x-1 ', 'flex flex-wrap')}>
          {uniqBy(selectedItems, 'value').map((item) => (
            <li key={item.id || item.value} className="inline ">
              <span
                className={classNames(
                  'inline-flex items-center px-2.5 py-0.5 rounded-full ',
                  'text-xs font-medium bg-indigo-100 text-indigo-800 justify-between items-center',
                )}
              >
                {item.label}
                <XIcon
                  className="h-3 w-3 ml-1 -mr-1 text-indigo-300 cursor-pointer"
                  onClick={() => {
                    setSelected(filter(selectedItems, (i) => i.value !== item.value));
                  }}
                />
              </span>
            </li>
          ))}
        </ul>
      )}
      <div className="relative mt-1">
        <Combobox.Input
          className={classNames(
            disabled ? 'bg-zinc-100' : 'bg-white',
            error
              ? 'w-full focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 rounded-md'
              : 'w-full rounded-md border border-gray-300  ',
            'py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1',
            'focus:ring-indigo-500 sm:text-sm',
          )}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(option) =>
            // eslint-disable-next-line no-nested-ternary
            option ? (typeof option.label === 'string' ? option.label : option.selectLabel) : ''
          }
        />

        <Combobox.Button
          className={classNames('absolute inset-y-0 right-0', 'flex items-center rounded-r-md px-2 focus:outline-none')}
        >
          {loading ? (
            <svg
              className={classNames('animate-spin -ml-1 mr-1 text-gray-400 h-5 w-5')}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="5" />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
            7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : (
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </Combobox.Button>

        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options
            className={classNames(
              'absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1',
              'text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
            )}
          >
            {filteredOptions.length > 0 ? (
              <>
                {filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.id || option.value}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9 ',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <div className="flex items-center">
                          {option.imageUrl && (
                            <img src={option.imageUrl} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                          )}
                          <span
                            className={classNames(
                              option.imageUrl ? 'ml-3' : '',
                              'block truncate',
                              (selected || find(selectedItems, { value: option.value })) && 'font-semibold',
                            )}
                          >
                            {option.label}
                          </span>
                        </div>

                        {(selected || find(selectedItems, { value: option.value })) && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
            ) : (
              <Combobox.Option
                disabled
                className={classNames('relative cursor-default select-none py-2 pl-3 pr-9 ', 'text-gray-500')}
              >
                {emptyLabel}
              </Combobox.Option>
            )}
          </Combobox.Options>
        </Transition>
      </div>
      {error && (
        <p className="mt-2 ml-1 text-sm text-red-600" id={`${label}-description`}>
          {error}
        </p>
      )}
    </Combobox>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      searchKey: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      imageUrl: PropTypes.string,
    }),
  ),
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      }),
    ),
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOfType([PropTypes.element, PropTypes.string]), // ?
  ]),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
  nullable: PropTypes.bool,
  searchFn: PropTypes.func,
  emptyLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  error: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  disabled: false,
  isMulti: false,
  nullable: true,
  emptyLabel: 'No Options',
};

export default memo(Select);
