import { createSelector } from 'reselect';
import { initialState, userProfileInitialState } from './reducer';

// const selectGlobal = (state) => state.global || initialState;
const selectRouter = (state) => state.router;

const selectApp = (state) => state || initialState;

const selectTasks = (state) => state || [];

const selectSaved = (state) => state || {};

const selectError = (state) => state || {};

const selectUserAuth = (state) => state.userAuth || userProfileInitialState;

const makeSelectLocation = () => createSelector(selectRouter, (routerState) => routerState.location);

const makeSelectError = () => createSelector(selectError, (state) => state.error);

const makeSelectTasks = () => createSelector(selectTasks, (state) => state);

const makeSelectApp = () => createSelector(selectApp, (state) => state);

const makeSelectSaved = () => createSelector(selectSaved, (state) => state);

const selectLoading = createSelector(selectUserAuth, (userAuthState) => userAuthState.loading);
const selectUser = createSelector(selectUserAuth, (userAuthState) => userAuthState.user);
const selectErrorUser = createSelector(selectUserAuth, (userAuthState) => userAuthState.error);

export {
  makeSelectLocation,
  makeSelectTasks,
  makeSelectError,
  makeSelectApp,
  makeSelectSaved,
  selectLoading,
  selectUser,
  selectErrorUser as selectError,
};
