/* eslint-disable no-unused-vars */
/* eslint-disable no-console, import/no-import-module-exports */

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import all the third party stuff
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { loadState } from 'utils/state';
import history from 'utils/history';
import { Workbox /* messageSW */ } from 'workbox-window';
import 'sanitize.css/sanitize.css';
import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './configureStore';
import { version } from './version';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';

// Import i18n messages
import { translationMessages } from './i18n';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      networkCaptureBodies: true,
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  release: process.env.VERSION || version || 'development',
  environment: process.env.NODE_ENV || 'development',
  enabled: true,
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.5,
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0,
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 0,
});

console.log('Version:', process.env.VERSION || version || 'dev');
console.log('Created at:', process.env.CREATED_AT && new Date(process.env.CREATED_AT));

const initialState = { saved: loadState() };
export const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

// eslint-disable-next-line no-console
const root = createRoot(MOUNT_NODE);
const render = (messages) => {
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <App />
        </LanguageProvider>
      </Provider>
    </BrowserRouter>,
  );
};

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(['./i18n', 'containers/App'], () => {
//     // root.unmount();
//     render(translationMessages);
//   });
// }

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => resolve(import('intl')))
    // eslint-disable-next-line import/extensions
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  const wb = new Workbox('/service-worker.js');
  // eslint-disable-next-line no-unused-vars
  let registration;
  const showSkipWaitingPrompt = (event) => {
    if (event.isUpdate) {
      // Notification({
      //   title: 'Update available!',
      //   message: 'A new version is available, click to update',
      //   duration: 0,
      //   onClick: async () => {
      //     if (registration && registration.waiting) {
      //       await messageSW(registration.waiting, { type: 'SKIP_WAITING' });
      //     }
      //     window.location.reload();
      //   },
      // });
    }
  };
  wb.addEventListener('waiting', showSkipWaitingPrompt);
  wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

  wb.addEventListener('installed', showSkipWaitingPrompt);

  // eslint-disable-next-line no-return-assign
  wb.register().then((r) => (registration = r));
}
