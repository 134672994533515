import * as Sentry from '@sentry/react';
import { set, get } from 'lodash';

const load = () => {
  let state = {};
  try {
    state = JSON.parse(localStorage.getItem('state') || '{}');
  } catch (e) {
    Sentry.captureException(e);
  }
  return state;
};

const setItem = (key, value) => {
  const state = load();
  set(state, `${key}`, value);
  localStorage.setItem('state', JSON.stringify(state));
  return null;
};

const getItem = (key) => {
  const state = load();
  return get(state, `${key}`);
};

export { load as loadState, setItem, getItem };
