/**
 *
 * Dropdown
 *
 */

import React, { memo, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { map, get } from 'lodash';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import messages from './messages';

export function Dropdown(props) {
  const { items, optionLabel, ControlIcon, location, customControl } = props;
  return (
    <Menu as="div" className="flex inline-block text-left">
      <div>
        {customControl || (
          <>
            {ControlIcon ? (
              <Menu.Button
                className=" rounded-full flex items-center
            hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2
            focus:ring-offset-gray-100 "
              >
                <span className="sr-only">Open options</span>
                <ControlIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            ) : (
              <Menu.Button
                className="inline-flex justify-center w-full rounded-md border
          border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium
          text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2
         "
              >
                {optionLabel || <FormattedMessage {...messages.options} />}
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            )}
          </>
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            location === 'top' ? ' origin-top-right' : 'origin-bottom-right',
            'w-56 rounded-md',
            'shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100',
          )}
        >
          {map(items, ({ group }, index) => (
            <div className="" key={index}>
              {map(group, (item) => (
                <Menu.Item key={get(group, 'id')}>
                  {({ active }) => (
                    <>
                      {item.Content ? (
                        <item.Content active={active} />
                      ) : (
                        <button
                          type="button"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                            !item.onClick ? 'disabled text-zinc-200' : '',
                          )}
                          onClick={item.onClick ? item.onClick : () => {}}
                        >
                          {item.Icon && <item.Icon className="h-5 w-5 inline" />} {item.label}
                        </button>
                      )}
                    </>
                  )}
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

Dropdown.propTypes = {
  items: PropTypes.array,
  location: PropTypes.oneOf(['top', 'bottom']),
  ControlIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  optionLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  customControl: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Dropdown.defaultProps = {
  location: 'bottom',
};

export default memo(Dropdown);
