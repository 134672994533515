import React from 'react';
import { map } from 'lodash';
import { Popover } from '@headlessui/react';

import PageCard from '../PageCard';

function Content({ apps, handleClick }) {
  return (
    <div className="whitespace-nowrap p-2 border rounded" data-testid="pageCardContent">
      <Popover.Button>
        {map(apps, (app) => (
          <PageCard
            key={app.route}
            icon={app.icon}
            name={app.name}
            summary={app.summary}
            route={app.route}
            onClick={handleClick}
          />
        ))}
      </Popover.Button>
    </div>
  );
}

export default Content;
