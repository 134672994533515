export const GET_USER_ROLES_QUERY = `
query UserRole {
  auth_user {
    user_roles {
      role
    }
  }
}`;

export const GET_AUTH_ROLES = `
  query getAuthRoles {
    auth_role {
      value
      description
    }
  }
`;

export const CREATE_AUTH_USER = `
  mutation createUser(
    $name: String!
    $roles: [role_enum!]!
    $tenant_id: Int!
    $username: String!
    $email: String!
    $external_id: String
    $phone_number: String
  ) {
    auth_create_user(
      name: $name
      roles: $roles
      tenant_id: $tenant_id
      username: $username
      email: $email
      external_id: $external_id
      phone_number: $phone_number
    ) {
      name
      username
      tenant_id
      email
      external_id
      phone_number
    }
  }
`;
export const FEATURE_REQUEST = `
  mutation createFeatureRequest($payload: [admin_create_feature_request_input!]!){
    admin_create_feature_request(data: $payload) {
      error_message
      success
    }
}`;

export const REPORT_ISSUE = `
mutation reportIssue($payload: [admin_create_support_ticket_input]!) {
  admin_create_support_ticket(data: $payload) {
    success
    error_message
  }
}
`;

export const DUPLICATE_FORM = `
 mutation duplicateForm($name: String!, $type: String!) {
    frm_create_form(name: $name, type: $type) {
      data {
        id
        name
        type
      }
      success
      error_message
    }
  }
`;
