/**
 *
 * NavigationItems
 *
 */

import React, { memo } from 'react';
import { NavigationIcon } from '../NavigationIcon';

export function NavigationItems(props) {
  const { expanded, selected, items } = props;

  const handleClick = () => {
    const redirectURL = 'https://wt.talview.org/workflow';
    window.open(redirectURL, '_blank');
  };

  return (
    <div className="flex flex-col gap-y-4 pt-8">
      {items?.map((module) => (
        <NavigationIcon
          item={module}
          image={module.image}
          icon={module.icon}
          key={module.name}
          label={module.label}
          expanded={expanded}
          selected={selected}
          onClick={handleClick}
          name={module.name}
        />
      ))}
    </div>
  );
}

NavigationItems.propTypes = {};

export default memo(NavigationItems);
