/**
 *
 * Checkbox
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';

export function Checkbox(props) {
  const { id, name, onChange, size, label, disabled } = props;
  const sizeMap = {
    xs: 'h-2 w-2',
    sm: 'h-3 w-3',
    md: 'h-4 w-4',
    lg: 'h-5 w-5',
  };
  const textMap = {
    xs: 'text-xs leading-0',
    sm: 'text-sm leading-1',
    md: 'text-md leading-2',
    lg: 'text-lg leading-4',
  };
  return (
    <div className="flex align-middle items-center">
      <input
        id={`checkbox-${id}`}
        name={`checkbox-${name}`}
        type="checkbox"
        onChange={(e) => onChange(e.target.value)}
        className={classNames(
          get(sizeMap, size, 'h3 w-3'),
          disabled ? 'text-zinc-300 text-gray' : 'text-indigo-600 focus:ring-indigo-500  ',
          'border-gray-300 rounded',
        )}
        disabled={disabled}
        {...props}
      />
      {label && (
        <label htmlFor={`checkbox-${name}`} className={classNames('ml-2', get(textMap, size, 'text-base'))}>
          {label}
        </label>
      )}
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xs']),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Checkbox.defaultProps = {
  size: 'md',
};
export default memo(Checkbox);
