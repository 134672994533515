import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Transition, Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/solid';

import { map } from 'lodash';

import classNames from 'classnames';

import { Checkbox } from '../Checkbox';

export function TreeNode(props) {
  const { checked, label, disabled, onChange, nodes, name, id, defaultOpen } = props;

  return (
    <div className="flex items-baseline space-x-1">
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <div>
            <Disclosure.Button>
              <div className={classNames('flex', !nodes || nodes.length === 0 ? 'px-8' : 'px-2', 'space-x-1')}>
                {nodes && nodes.length > 0 && (
                  <ChevronRightIcon className={`${open ? 'transform rotate-90' : ''} h-5 w-5`} />
                )}
                <Checkbox
                  onChange={() => onChange({ key: id, checked: !checked })}
                  checked={checked}
                  disabled={disabled}
                  name={name}
                  id={id}
                  label={label}
                />
              </div>
            </Disclosure.Button>
            {open && (
              <Transition
                enter="transition-opacity ease-linear duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Disclosure.Panel static>
                  <div className="pl-3">
                    {nodes && nodes.length > 0 && (
                      <div key={id}>
                        {map(nodes, (node) => (
                          <TreeNode {...node} onChange={onChange} />
                        ))}
                      </div>
                    )}
                  </div>
                </Disclosure.Panel>
              </Transition>
            )}
          </div>
        )}
      </Disclosure>
    </div>
  );
}

TreeNode.propTypes = {
  nodes: PropTypes.array,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onChange: PropTypes.func,
  defaultOpen: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

TreeNode.defaultProps = {
  defaultOpen: true,
  checked: false,
};

export default memo(TreeNode);
