import React from 'react';
import { FormattedMessage } from 'react-intl';
import request from 'images/request.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Dropdown } from 'components/Dropdown';
import Button from 'components/TableFilters/NewButton';
import messages from './messages';

const Content = ({ topics, topic, setTopic, handleChange, handleSubmit, loading, suggestion, onClose }) => (
  <>
    <span
      role="none"
      className="absolute flex justify-center items-center left-[-2.5rem] top-3 rounded-full bg-white cursor-pointer"
      onClick={onClose}
    >
      <FontAwesomeIcon icon={faXmark} className="h-4 w-4 p-2" />
    </span>
    <div className="font-medium leading-4.5 text-base mb-6 text-gray-900">
      <FormattedMessage {...messages?.request_feature} />
    </div>
    <img src={request} className="w-full h-max-[214px] " alt="request-feature-img" />
    <div className="font-normal leading-4 text-sm mb-2  text-gray-900">
      <FormattedMessage {...messages?.select_a_topic} />
    </div>
    <div data-testid="topics">
      <Dropdown
        value={topic}
        name="provider-select"
        options={topics}
        onChange={(value) => setTopic(value)}
        placeholder="Select an option"
        labelKey="label"
        primaryKey="value"
        id="topics"
        isDisabled={loading}
      />
    </div>

    <div className="font-normal leading-4 text-sm mb-2 pt-6  text-gray-900">
      <FormattedMessage {...messages?.how_to_improve} />
    </div>
    <textarea
      className="w-full h-[173px] border border-[#D1D5DB] rounded"
      placeholder="Jot down your suggestions..."
      onChange={handleChange}
      data-testid="suggestion"
      disabled={loading}
    ></textarea>

    <div className="flex gap-1 justify-end pt-8">
      <Button type="tertiary" size="lg" disabled={loading} onClick={onClose}>
        <FormattedMessage {...messages?.cancel} />
      </Button>
      <Button size="lg" onClick={handleSubmit} loading={loading} disabled={loading || !suggestion || !topic}>
        <FormattedMessage {...messages?.submit} />
      </Button>
    </div>
  </>
);

export default Content;
