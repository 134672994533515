import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import Input from '../Input';
import Button from '../Button';
import SelectDropdown from '../SelectDropdown';

export function Tab({ onCancel, options }) {
  const [selectedTab, setSelectedTab] = useState(1);

  const cancelFn = () => {
    if (onCancel) onCancel();
  };

  const toggleTab = (index, disabled) => {
    if (!disabled) {
      setSelectedTab(index);
    }
  };

  return (
    <>
      <div className="flex justify-items-center bg-blue-700 bg-opacity-5 pt-4 px-6 -mx-6">
        {options.map((option, index) => (
          <div
            key={option.id}
            role="presentation"
            className={`flex flex-col items-center w-[175px] ${
              selectedTab === index + 1 ? 'border-b-2 border-blue-600' : ''
            }`}
            onClick={() => toggleTab(index + 1, option.disabled)}
          >
            {option.image ? (
              <img
                src={option.image}
                alt="img"
                className={`w-[70px] h-[116px] bg-white rounded-lg ${
                  selectedTab === index + 1 ? 'border-2 border-blue-600' : 'cursor-pointer'
                }`}
              />
            ) : (
              <div
                className={`w-[70px] h-[116px] bg-white rounded-lg ${
                  selectedTab === index + 1 ? 'border-2 border-blue-600' : 'cursor-pointer'
                }`}
              />
            )}
            <div
              className={`text-slate-500 py-[10px] font-semibold ${selectedTab === index + 1 ? 'text-blue-600' : ''}`}
            >
              {option.label}
            </div>
          </div>
        ))}
      </div>

      {options.map(
        (option, index) =>
          selectedTab === index + 1 &&
          !option.disabled && (
            <div key={option.id} className="pt-4">
              <div className="mb-2">
                <label className="text-sm font-medium text-gray-700" htmlFor="selectForm">
                  {option.selectLabel}
                </label>
              </div>
              <SelectDropdown
                id="selectForm"
                closeMenuOnSelect
                error={option.selectError}
                value={option.selectValue}
                options={option.selectOptions}
                placeholder={option.placeholder}
                onChange={(e) => {
                  if (isFunction(option.selectOnChange)) {
                    option.selectOnChange(e);
                  }
                }}
                labelKey="label"
                primaryKey="value"
              />
              <div className="pt-4">
                <Input
                  error={option.inputError}
                  label={option.inputLabel}
                  defaultValue={option.inputValue}
                  onChange={(e) => {
                    if (isFunction(option.inputOnChange)) {
                      option.inputOnChange(e);
                    }
                  }}
                  placeholder={option.inputPlaceholder}
                  required
                />
              </div>
              <div className="flex justify-end space-x-2 pt-8">
                <Button type="secondaryTransparent" onClick={cancelFn} disabled={option.cancelButtonDisabled}>
                  {option.cancelButtonChildren || 'Cancel'}
                </Button>
                <Button
                  data-testid="create-form-button"
                  type="success"
                  disabled={option.createButtonDisabled}
                  onClick={() => {
                    if (isFunction(option.createButtonOnClick)) {
                      option.createButtonOnClick();
                    }
                  }}
                >
                  {option.createButtonChildren || 'Create form'}
                </Button>
              </div>
            </div>
          ),
      )}
    </>
  );
}

Tab.propTypes = {
  onCancel: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      label: PropTypes.string.isRequired,
      selectLabel: PropTypes.string.isRequired,
      selectValue: PropTypes.object,
      inputLabel: PropTypes.string.isRequired,
      inputPlaceholder: PropTypes.string.isRequired,
      cancelButtonChildren: PropTypes.string,
      createButtonChildren: PropTypes.string,
      createButtonOnClick: PropTypes.func,
      selectOnChange: PropTypes.func,
      selectOptions: PropTypes.array,
      inputValue: PropTypes.string,
      inputOnChange: PropTypes.func,
      disabled: PropTypes.bool,
      cancelButtonDisabled: PropTypes.bool,
      createButtonDisabled: PropTypes.bool,
      selectError: PropTypes.string,
      inputError: PropTypes.string,
      placeholder: PropTypes.string,
    }),
  ).isRequired,
};

export default memo(Tab);
