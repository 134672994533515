import { catchError } from 'utils/helpers';

export const captureScreen = async ({ callback }) => {
  try {
    // Check if the browser supports the `getDisplayMedia` API
    if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
      try {
        // Capture the screen
        const stream = await navigator.mediaDevices.getDisplayMedia({
          preferCurrentTab: true,
          selfBrowserSurface: 'include',
        });

        // Create a video element to display the screen capture
        const videoElement = document.createElement('video');
        videoElement.srcObject = stream;
        videoElement.autoplay = true;

        // Wait for the video element to be ready
        await new Promise((resolve) => {
          videoElement.onloadedmetadata = resolve;
        });

        // Create a canvas element to draw the screen capture
        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;

        // Draw the screen capture on the canvas
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoElement, 0, 0);

        // Convert the canvas data to a base64 image URL
        const base64Image = canvas.toDataURL('image/png');

        // Clean up
        videoElement.pause();
        videoElement.srcObject = null;
        stream.getTracks().forEach((track) => track.stop());

        // Use the captured image URL (base64Image) as needed
        callback.onSuccess(base64Image);
      } catch (error) {
        // Error capturing screen
        callback.onError(error);
        catchError(error, true);
      }
    } else {
      // Fallback for browsers that do not support screen capture
      const error = new Error('Screen capture is not supported in this browser.');
      callback.onError(error);
      catchError(error, true);
    }
  } catch (error) {
    // MediaDevices API not available or unsupported
    // console.error('MediaDevices API not available or unsupported:', error);
    callback.onError(error);
    catchError(error, true);
  }
};
