/**
 *
 * DatePicker
 *
 */

import React, { memo, useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Transition } from '@headlessui/react';
import { CalendarIcon, XIcon } from '@heroicons/react/solid';

import Calendar from '../Calendar';
import Input from '../Input';

export function DatePicker(props) {
  const { onChange, label, placeholder, nullable, disabled, value } = props;
  const [val, setValue] = useState(value || null);
  const [open, setOpen] = useState(false);
  const changeFn = (v) => {
    setValue(v);
    setOpen(false);
  };
  useEffect(() => {
    if (onChange) onChange(val);
  }, [val]);
  return (
    <div className="flex flex-col w-fit">
      <Input
        placeholder={placeholder}
        defaultValue={val}
        label={label}
        disabled={disabled}
        onChange={setValue}
        readOnly="readonly"
        leadingIconOnClick={() => {
          if (!disabled) setOpen(!open);
        }}
        LeadingIcon={CalendarIcon}
        trailingIconOnClick={() => {
          if (!disabled && nullable && val) setValue(null);
        }}
        TrailingIcon={!disabled && nullable && val ? XIcon : null}
        type="text"
      />
      <Transition.Root show={open} as={Fragment}>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-1">
            <Calendar onChange={changeFn} value={val} />
          </div>
        </Transition>
      </Transition.Root>
    </div>
  );
}

DatePicker.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  nullable: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

DatePicker.defaultProps = {
  nullable: true,
  disabled: false,
};

export default memo(DatePicker);
